import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import TransferBanner from '../components/FeatureBanner'
import Question from '../components/Question'
import ListFlow from '../components/ListFlow'
import StartNow from '../components/StartNow'
import StepSlide from '../components/StepSlide'
import Card from '../components/Card'

import TransferMain from '../images/transfer-main.png'
import RemoteMainMobile from '../images/FileTransferBgMobile.png'

import BreakLimits from '../images/BreakLimits.png'
import FasterSafer from '../images/FasterSafer.png'
import CrossDevices from '../images/CrossDevices.png'

import OpenTool from '../images/OpenTool.png'
import SelectFiles from '../images/SelectFiles.png'
import TransferFiles from '../images/TransferFiles.png'
import DragFiles from '../images/DragFiles.png'
import ClipboardFiles from '../images/ClipboardFiles.png'

import CorpStaff from '../images/CorpStaff.png'
import Designer from '../images/Designer.png'
import Educator from '../images/Educator.png'

const TransferFile = () => {
  const descList = [
    'Remote file transfer between computer/mobile devices.',
    'Fast file transfer, 100 times faster than FTP/HTTP.',
    'No limitations on file size, quantity, or format.',
    'Equipped with TLS encryption technology for data security and integrity',
  ]
  const faqList = [
    {
      question: 'Is there a free File Transfer?',
      answer:
        "Yes, there are free file transfer options available. Avica is one of the most recommended solutions for file transfer. Both Avica's Free Plan and Pro Plan offer the file transfer feature, which means you can take advantage of Avica's file transfer capabilities without any cost.",
    },
    {
      question:
        'Can file transfer be done across different devices and operating systems?',
      answer:
        'Avica currently supports file transfer between the computers,  the mobile device and the computer. File sharing between mobile devices will soon be available. Please stay tuned for the upcoming release!',
    },
    {
      question:
        'What type of network connection is required for file transfer?',
      answer:
        'Different file transfer solutions require different types of network connections. If you are using Avica for file transfer, you will need an Internet connection to perform the transfer.',
    },
  ]

  const flowItemList = [
    {
      img: BreakLimits,
      img_mobile: BreakLimits,
      title: 'Break All Limits',
      desc: "Whether you're dealing with large files, a multitude of files, or different file formats, Avica has got you covered. Experience the freedom of transferring files without any limitations with Avica's file sharing solution. Say goodbye to barriers and enjoy effortless file transfer now!",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
    {
      img: FasterSafer,
      img_mobile: FasterSafer,
      title: 'Faster and Safer',
      desc: "With AES-256 encryption technology and TLS-encrypted transmission, your data remains safe and intact throughout the entire transfer process. But that's not all - Avica's patented protocol ensures efficient delivery even when dealing with massive amounts of data, 100 times faster than FTP/HTTP. With just a simple click, experience swift and secure file transfer like never before.",
      ifRotation: true,
      ifShowDownloadBtn: false,
    },
    {
      img: CrossDevices,
      img_mobile: CrossDevices,
      title: 'Cross-Device Share Files',
      desc: "Avica's built-in File Transfer tool enables seamless sharing of files between computers, such as PC and Mac. It also facilitates file transfer between mobile devices and computers, including Android and iOS devices. Users are able to effortlessly transfer files across various devices, ensuring a smooth and efficient sharing experience.",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
  ]

  const tab1List = [
    {
      img: OpenTool,
      title: 'Step 1. Open File Transfer Tool',
      desc: 'Launch the File Transfer tool within Avica. You can initiate the File Transfer tool whether you are accessing remotely or not.',
      redirect: false,
    },
    {
      img: SelectFiles,
      title: 'Step 2. Select Files',
      desc: 'Select the target files you wish to transfer in the file manager and choose the destination location on the target device which you want the files to be transferred to.',
      redirect: false,
    },
    {
      img: TransferFiles,
      title: 'Step 3. Transfer Files',
      desc: 'Click the "Send" button to swiftly transfer the files from your device to the target device.',
      redirect: true,
    },
  ]

  const tab2List = [
    {
      img: DragFiles,
      title: 'Step 1. Select Files',
      desc: 'After initiating the remote session, choose the target file you want to transfer from your device.',
      redirect: false,
    },
    {
      img: DragFiles,
      title: 'Step 2. Drag and Drop Files',
      desc: 'Drag and drop files from your client device into the remote session interface of the host device, and vice versa. Your files will be transferred to the dropped location immediately.',
      redirect: false,
    },
  ]

  const tab3List = [
    {
      img: ClipboardFiles,
      title: 'Step 1. Select Files',
      desc: 'Upon establishing the remote session, select the files you wish to transfer from your device.',
      redirect: false,
    },
    {
      img: ClipboardFiles,
      title: 'Step 2. Copy and Paste Files',
      desc: 'Right-click on the files and select "Copy" or use the shortcut Ctrl+C to copy the files. Then, navigate to the desired location on the target device, right-click in an empty space, and select "Paste" or use the shortcut Ctrl+V to complete the file transfer.',
      tip: '* The Clipboard feature also supports copying and pasting text.',
      redirect: false,
    },
  ]

  const More = [tab1List, tab2List, tab3List]

  const tabInfoList = [
    {
      tabName: 'File Transfer Tool',
      ifShowSelectBox: true,
      isPlay: true,
    },
    {
      tabName: 'File Drag and Drop',
      ifShowSelectBox: false,
      isPlay: false,
    },
    {
      tabName: 'Clipboard',
      ifShowSelectBox: false,
      isPlay: false,
    },
  ]

  const cardList = [
    {
      img: CorpStaff,
      title: 'Corporate Staff',
      desc: "Whether it's sending massive reports, sharing high-resolution images, or transferring important contract documents, Avica becomes your capable assistant. With no limitations on file size, quantity, or format, whether collaborating with colleagues or sharing documents with clients, you can effortlessly transfer files and enhance your work efficiency.",
    },
    {
      img: Designer,
      title: 'Designer',
      desc: "Frequently handle a multitude of multimedia files such as high-definition videos, audio, and images? Avica empowers you to freely share these large files. Whether it's sharing uncompressed HD video clips with colleagues or sending raw audio files to clients, Avica ensures a seamless transfer process with no restrictions on size, quantity, or format.",
    },
    {
      img: Educator,
      title: 'Educator',
      desc: "Whether it's sharing learning resources with students in the classroom or collaborating with colleagues on teaching materials, Avica enables fast and reliable transmission of these files while protecting sensitive information. Educators can easily share and exchange files with students and colleagues, improving teaching effectiveness and learning outcomes with Avica.",
    },
  ]

  return (
    <Layout pageType="transferFile">
      <TransferBanner
        title="Rapid File Transfer"
        descList={descList}
        photo={TransferMain}
        mobile={RemoteMainMobile}
      />
      <ListFlow
        featureList={flowItemList}
        title="Easily Transfer Files During Remote Access"
        desc="Unleash unrestricted and secure file sharing between devices with Avica's solution."
      />
      <StepSlide
        title="How to Share Files Between Devices with Avica?"
        ifShowTab
        More={More}
        TabInfo={tabInfoList}
        className_title="has-tab-title"
        guideLink="https://www.avica.link/help-center/file-transfer/"
      />
      <Card
        cardList={cardList}
        title="Boost Your Efficiency with Avica's File Transfer"
      />
      <Question faqList={faqList} />
      <StartNow pre="Start Secure and Rapid" span="File Transfer" suf="Now" />
    </Layout>
  )
}

export default TransferFile
